<template>
  <div class="header-item">
    <div class="item-content">
      <label>部门：</label>
      <el-select v-model="Value" placeholder="请选择部门" @change="changeDepartment" filterable clearable
        @clear="clearDepartment">
        <el-option v-for="item in options" :key="item.id" :label="item.label" :value="item.id">
        </el-option>
      </el-select>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        options: [], //部门列表select
        loading: false,
        Value: '',
      }
    },
    mounted() {
      this.getMyTeams()
      console.log(this.Value);
    },

    methods: {

      // 获取我的团队列表部门
      async getMyTeams() {
        let res = await this.$ZGManager.getMyTeams();
        if (res.status == 200) {
          res.data.map((e) => {
            this.options.push({
              id: e.id,
              label: e.name,
            });
          });
        }
        this.options.unshift({
          id: '',
          label: '全部部门'
        })
      },
      clearDepartment() {
        this.$emit('clearDepartment')
      },
      changeDepartment() {
        this.$emit('changeDepartment', this.Value)
      }
    },
  }
</script>