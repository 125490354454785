<template>
  <div>
    <div class="container">

      <!-- 功能区 -->
      <div class="header">
        <div class="header-item">
        </div>
        <department-query  @clearDepartment="getList(1)" @changeDepartment="changeDepartment($event)" ></department-query>
        <div class="header-item">
          <el-input
            placeholder="请输入员工姓名"
            suffix-icon="el-icon-search"
            clearable @clear="getList(1)"
            v-model="staffParams.name">
          </el-input>
        </div>
        <div class="header-item">
          <el-button type="primary" class="el-icon-search"  @click="getList(1)">搜索</el-button>
        </div>
      </div>

      <!-- 列表区 -->
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%; margin-top: 10px"
        header-row-class-name="table-header-style"
        :header-cell-style="{'background-color': 'rgba(249, 249, 249)'}"
        >
        <!-- 员工姓名 -->
        <el-table-column label="员工姓名" width="200">
          <template slot-scope="scope">
            <p >{{ scope.row.name }}</p>
          </template>
        </el-table-column>
        <!-- 所属部门 -->
        <el-table-column prop="source" label="所属部门">
          <template slot-scope="scope">
            <p>{{scope.row.teamName || "暂无部门信息"}}</p>
          </template>
        </el-table-column>
        <!-- 分享文章数 -->
        <el-table-column label="分享文章数" align="center" >
          <template slot-scope="scope">
            <p>{{ scope.row.shareCount || 0 }}</p>
          </template>
        </el-table-column>
        <!-- 获客数 -->
        <el-table-column label="获得线索数" align="center">
          <template slot-scope="scope">
            <p>{{ scope.row.visitCount || 0 }}</p>
          </template>
        </el-table-column>
        <!-- 分享明细 -->
        <el-table-column label="操作" width="70">
          <template slot-scope="scope">
            <el-button @click="showShare(scope.row.id)" type="text" size="small">分享明细</el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页列表 -->
      <div style="float: right; margin-top: 10px">
        <el-pagination
          @current-change="getList"
          :current-page="staffParams.pageIndex"
          :page-size="staffParams.pageNumber"
          background
          layout="total, prev, pager, next, jumper"
          :total="staff_total_rows">
        </el-pagination>
      </div>

      <!-- 分享明细 -->
      <el-dialog title="分享明细" :visible.sync="dialogVisible" width="1200px" :close-on-press-escape="false"
        :close-on-click-modal="false"
        lock-scroll modal-append-to-body :append-to-body="true" class="share-dialog" style="overflow: hidden">
        <el-table class="dialog"
          ref="multipleTable"
          :data="shareData"
          tooltip-effect="dark"
          style="width: 100%; margin-top: 10px"
          :header-cell-style="{'background-color': 'rgba(249, 249, 249)'}"
         >
          <!-- 文章标题 -->
          <el-table-column label="文章标题" >
            <template slot-scope="scope">
              <p style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">{{ scope.row.rn }}</p>
            </template>
          </el-table-column>
          <!-- 发布时间 -->
          <el-table-column label="发布时间" width="250px">
            <template slot-scope="scope">
              <p>{{ scope.row.created_at | dateFormat('YYYY-MM-DD HH:mm:ss') }}</p>
            </template>
          </el-table-column>
          <!-- 分享次数 -->
          <el-table-column prop="shareCount" width="150px" align="center" label="分享次数"></el-table-column>
          <!-- 浏览次数 -->
          <el-table-column prop="visitCount"  width="150px" align="center" label="浏览次数"></el-table-column>
          <!-- 获客数 -->
          <el-table-column prop="customerCount"  width="150px" align="center" label="获客数"></el-table-column>
        </el-table>
         <div style="float: right; margin-top: 10px; margin-bottom: 10px">
          <el-pagination
             @current-change="getShareMemberDetail"
          :current-page="shareParams.pageIndex"
          :page-size="shareParams.pageNumber"
          background
          layout="total, prev, pager, next, jumper"
          :total="share_total_rows">
          </el-pagination>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import DepartmentQuery from '@/websites/cms/components/DepartmentQuery'
export default {
  data(){
    return {
        tableData: [],
        dialogVisible: false,
        staffParams:{
          pageIndex:1,
          pageNumber:8,
          name:'',
          teamId:'',
        },
        staff_total_rows:0,
        share_total_rows:0,
        shareData:[],//分享明细数据
        shareParams:{
          id:'',
          pageIndex:1,
          pageNumber:8
        }
    }
  },
  components:{
    'department-query':DepartmentQuery
  },

  created() {
    this.getList(1);
  },
  methods: {
    //点击选择部门
    changeDepartment(data) {
      console.log(data);
      this.staffParams.teamId = data;
      this.getList(1);
    },
    //显示分享明细table
    async showShare(id) {
      this.dialogVisible = true;
      this.shareParams.id = id;
      this.getShareMemberDetail(1);
    },
    //获取分享明细数据
    async getShareMemberDetail(page) {
      this.shareParams.pageIndex = page;
      let res = await this.$ZGManager.shareMemberDetail(this.shareParams);
      this.shareData=res.data.list;
      this.share_total_rows = res.data.total_rows;
      console.log(res);
    },
    //获取员工数据
    async getList(page) {
      this.staffParams.pageIndex = page;
      if(this.staffParams.name == '') delete this.staffParams.name;
      if(this.staffParams.teamId == '') delete this.staffParams.teamId;
      let res = await this.$ZGManager.allMemberList(this.staffParams)
      if(res.status !==200) return;
      this.staff_total_rows = res.data.total_rows;
      this.tableData = res.data.list;
    }
  }
}
</script>

<style lang="scss" scoped>
.container{
  background-color: #fff;
  padding: 15px 10px;
  overflow: hidden;
}
.header{
  position: relative;
}
.header-item{
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
}
.dialog{
  // height: 400px;
}
.share-dialog ::v-deep .el-dialog{
  overflow: hidden;
  max-height: 90%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin-top: 0!important;
}
</style>
